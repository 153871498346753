'use client';

import { theme } from '@/styles/theme';
import { MantineProvider, Global } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import RootStyleRegistry from './emotion';
import { AuthProvider } from '@/context/AuthContext';

const queryClient = new QueryClient();
function MyGlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        // input : {
        //   backgroundColor: 'red important',
        //   color: 'blue !important',
        //   lineHeight: theme.lineHeight,
        // },

        '.mantine-Input-input:disabled': {
          color: '#000000 !important',
        },

        '#your-id > [data-disabled]': {
          backgroundColor: 'pink',
        },
      })}
    />
  );
}
export function Provider({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <RootStyleRegistry>
        <AuthProvider>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              ...theme,
              loader: 'oval',

              components: {
                TextInput: {
                  styles: {
                    root: {
                      input: {
                        color: '#000',
                      },
                      '&:is(data-disabled)': { color: 'red', fontSize: '4rem' },
                    },
                  },
                },
              },
            }}
          >
            <MyGlobalStyles />
            <Notifications position="top-right" zIndex={2077} />
            <ModalsProvider>{children}</ModalsProvider>
          </MantineProvider>
        </AuthProvider>
      </RootStyleRegistry>
    </QueryClientProvider>
  );
}
