//This file contain project theme colors and other decoration
import type { MantineThemeOverride } from '@mantine/core';

// export theme
export const theme: MantineThemeOverride = {
  primaryColor: 'indigo',
  primaryShade: 5,
  breakpoints: {
    xs: '576',
    sm: '768',
    md: '992',
    lg: '1200',
    xl: '1440',
  },
};

export const AnchorStyle = {
  textDecoration: 'inherit',
  color: '#ADB5BD',
  cursor: 'pointer',
};

export const IconColorGreen = '#27CA90';

export const IconColorRed = '#ee0000';

export const IconColorBlue = '#03d3fc';

export const imageStyleBox = { position: 'relative' };

export const wrapText = {
  whiteSpace: 'pre-line !important',
  wordWrap: 'break-word',
};
