// src/app/RouteTracker.tsx
'use client';

import { useEffect } from 'react';
import { usePathname } from 'next/navigation';
import appInsightsInstance from '../utils/AppInsights';
interface RouteTrackerProps {
    appInsightsKey: string;
  }
  
  export default function RouteTracker({ appInsightsKey }: RouteTrackerProps) {
    const pathname = usePathname();

  useEffect(() => {
    if (appInsightsKey) {
      appInsightsInstance.initialize(appInsightsKey);
      console.log('Application Insights initialized');
    }

    // Track page view on route change
    appInsightsInstance.trackPageView({ name: pathname });
  }, [pathname]);

  return null;
}
