import { notifications } from '@mantine/notifications';
import { IconCheck } from '@tabler/icons-react';

const useNotification = () => {
  const triggerNotification = (props: any) => {
    const {
      withCloseButton = true,
      autoClose = 5000,
      title = 'Error!',
      message = '',
      icon = <IconCheck />,
      selectedColor = 'red',
      loading = false,
    } = props;

    notifications.show({
      withCloseButton,
      autoClose,
      title,
      message,
      color: selectedColor,
      icon,
      className: 'my-notification-class',
      style: { borderColor: selectedColor },
      sx: { borderColor: selectedColor },
      loading,
    });
  };
  return { triggerNotification };
};

export default useNotification;
