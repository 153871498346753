// src/lib/appInsights.ts
import { ApplicationInsights, IEventTelemetry, IPageViewTelemetry } from '@microsoft/applicationinsights-web';

class AppInsights {
  private appInsights: ApplicationInsights | null = null;

  initialize(instrumentationKey: string) {
    if (!this.appInsights && instrumentationKey) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey,
          enableAutoRouteTracking: true,
        },
      });
      this.appInsights.loadAppInsights();
    }
  }

  trackPageView(pageView: IPageViewTelemetry) {
    this.appInsights?.trackPageView(pageView);
  }

  trackEvent(event: IEventTelemetry) {
    this.appInsights?.trackEvent(event);
  }
}

export default new AppInsights();
